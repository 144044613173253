import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import { excelHelper } from 'helpers';
import { getExcelDownload, getPriceForExcelDownload } from 'store/excel/tasks';
import { getSettings, updateSettings } from 'store/user/tasks';
import ActionButton from 'components/action_button';
import Collapsible from 'components/collapsible';
import Highlight from 'components/highlight';
import Icon from 'components/icon';
import Loading from 'components/loading';
import Menu from 'components/menu';
import Popup from 'components/popup';
import ToggleOption from 'components/toggle_option';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import ExcelPreset from './excel_preset';
import { request } from 'helpers';

/**
 * Component used for excel output in lists and fleet pages.
 *
 * Origin - From what source is the export initiated (lists or fleet).
 *          Determines if we go by list ids and build the search based on list
 *          criterias and/or list prospects, or if we go by a prospect id.
 *          For fleet origin we can also include 'koncern' param which includes
 *          all koncern companies for the export.
 *
 * Type - Car or user information, this determines how we search elastic.
 *        So if an export contains 10 prospects that owns 2 cars each,
 *        selecting "user" will result in 10 rows and selecting "car" will
 *        result in 20 rows.
 *        If they select type "car" and also select columns from the user columns
 *        (like Name, Postort etc.) we will charge for the 20 vehicle rows but
 *        also charge for 10 prospect rows since this information will be added to each row.
 *        If they have paid for name or management information in a list, we will
 *        give them discount for the "user" rows. See pricing in shared_helpers/cost_helper.js
 *
 * We have setup this component to work as templates. Selecting "Prospekt" template
 * is the same as setting type to "user", and selecting "Fordon" template is the same
 * as setting type to "car". After selecting this the user gets to select exactly which
 * fields/columns they want in the export. So they're not really a template. Reason is
 * we want to be able to have actual templates, and use the same system for both.
 * Currently we have one true template (Brevutskick) which sets type to "user" and a certain
 * predetermined columns to active.
 *
 * @param state.props.close - func - Close component.
 * @param state.props.koncern - bool (optional) - When sending koncern param to backend, applicable for fleet.
 * @param state.props.prospectId - string - When origin === 'fleet' this needs to be a prospect id.
 * @param state.props.selectedLists - array - When origin === 'lists' this needs to be an array of lists.
 * @param state.props.origin - string - 'fleet' | 'list'
 */
const Excel = (state) => {
  const [approveCost, setApproveCost] = useState(false);
  const [haveActiveUserColumns, setHaveActiveUserColumns] = useState(false);
  const [loading, setLoading] = useState(false);
  const [removeProspectsWithoutNames, setRemoveProspectsWithoutNames] =
    useState(false);
  const [responsiveClassWidth, setResponsiveClassWidth] = useState('');
  const [stage, setStage] = useState(1);
  const [selectedTemplate, setSelectedTemplate] = useState(null); // Current possible templates: "prospect", "vehicle", "mailings".
  const [columns, setColumns] = useState(null);
  const [userPresets, setUserPresets] = useState([]);
  const [userPresetsIsLoading, setUserPresetsIsLoading] = useState(false);
  const [presetToEdit, setPresetToEdit] = useState({});
  const [selectedUserPresetType, setSelectedUserPresetType] = useState(null);
  const [createPreset, setCreatePreset] = useState(false);
  const excelRef = useRef(null);
  const observer = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    _setResponsive();
    observer.current = new ResizeObserver(_setResponsive);
    observer.current.observe(excelRef.current);

    return () => {
      observer.current.unobserve(excelRef.current);
      setColumns(null);
      setStage(1);
      setSelectedTemplate(null);
      setSelectedUserPresetType(null);
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    const getUserPresets = async () => {
      try {
        if (!userPresetsIsLoading) {
          setUserPresetsIsLoading(true);
          const presets = await request({
            url: '/settings/excelPresets',
            method: 'GET',
          });

          if (state.props.origin === 'fleet') {
            setUserPresets(presets.filter((preset) => preset.type === 'car'));
          } else {
            setUserPresets(presets);
          }
          setUserPresetsIsLoading(false);
          setSelectedUserPresetType(null);
        }
      } catch (err) {
        console.error(err);
      }
    };
    if (stage === 1) {
      getUserPresets();
    }
  }, [stage]);

  useEffect(() => {
    if (state.props.origin === 'fleet') {
      // For fleet we only allow vehicle rows so skip first step.
      // setSelectedTemplate('vehicle');
      setSelectedUserPresetType(null);
      setStage(1);
    }
  }, [state.props.origin]);

  useEffect(() => {
    if (columns) {
      let columnsWithActiveValues = JSON.parse(JSON.stringify(columns));

      // Go ahead and remove unactive values and columns that has no active values.
      for (const prop in columnsWithActiveValues) {
        columnsWithActiveValues[prop] = columnsWithActiveValues[prop].filter(
          (num) => num.active
        );
        if (!columnsWithActiveValues[prop].length) {
          delete columnsWithActiveValues[prop];
        }
      }

      setHaveActiveUserColumns(!!columns.hasOwnProperty('userColumns'));
    }
  }, [columns]);

  useEffect(() => {
    _setActiveColumnsFromSettings();
  }, [state.user.settings, state.props.origin, selectedTemplate]);

  useEffect(() => {
    if (stage === 1) {
      _setActiveColumnsFromSettings();
    }
  }, [stage]);

  const _close = () => {
    if (typeof state.props.close === 'function') {
      state.props.close();
    }
  };

  const deletePreset = async (id) => {
    try {
      await request({
        url: `/settings/excelPresets?presetId=${id}`,
        method: 'DELETE',
      });
      setSelectedTemplate(null);
      setStage(2);
      setStage(1);
      setPresetToEdit({});
    } catch (err) {
      console.error(err);
    }
  };

  const _convertToExcel = async () => {
    setLoading(true);
    let columnsWithActiveValues = JSON.parse(JSON.stringify(columns));

    // Remove unactive values and columns without any active values.
    for (const prop in columnsWithActiveValues) {
      columnsWithActiveValues[prop] = columnsWithActiveValues[prop].filter(
        (num) => num.active
      );
      if (!columnsWithActiveValues[prop].length) {
        delete columnsWithActiveValues[prop];
      }
    }

    await getExcelDownload({
      haveActiveUserColumns: haveActiveUserColumns,
      koncern: state.props.origin === 'fleet' ? state.props.koncern : null,
      listIds:
        state.props.origin === 'list'
          ? state.props.selectedLists.map((list) => list.id)
          : null,
      removeProspectsWithoutNames: removeProspectsWithoutNames,
      columns: columnsWithActiveValues,
      origin: state.props.origin,
      prospectId:
        state.props.origin === 'fleet' ? state.props.prospectId : null,
      type:
        selectedTemplate === 'vehicle' || selectedUserPresetType === 'car'
          ? 'car'
          : 'user',
    });

    setLoading(false);
    setStage(4);
  };

  const _getPrice = async () => {
    let columnsWithActiveValues = JSON.parse(JSON.stringify(columns));

    // Go ahead and remove unactive values and columns that has no active values.
    for (const prop in columnsWithActiveValues) {
      columnsWithActiveValues[prop] = columnsWithActiveValues[prop].filter(
        (num) => num.active
      );
      if (!columnsWithActiveValues[prop].length) {
        delete columnsWithActiveValues[prop];
      }
    }

    await getPriceForExcelDownload({
      haveActiveUserColumns: haveActiveUserColumns,
      koncern: state.props.origin === 'fleet' ? !!state.props.koncern : null,
      prospectId:
        state.props.origin === 'fleet' ? +state.props.prospectId : null,
      listIds:
        state.props.origin === 'list' &&
        Array.isArray(state.props.selectedLists)
          ? state.props.selectedLists.map((list) => list.id)
          : null,
      origin: state.props.origin,
      removeProspectsWithoutNames:
        state.props.origin === 'list' ? removeProspectsWithoutNames : null,
      type:
        state.props.origin === 'fleet'
          ? 'car'
          : selectedTemplate === 'vehicle' || selectedUserPresetType === 'car'
            ? 'car'
            : 'user',
    });
  };

  const _isSectionToggled = (section) => {
    return columns[section].find((num) => !num.active);
  };

  const _renderApproveCost = () => {
    return (
      <div className="excelWrapper__excel__content__summary__approveCost">
        <p>
          {tc.approveCostExcelInformationSummary1}{' '}
          {Number.isFinite(state.excel.rowPriceProspect) ? (
            state.excel.rowPriceProspect
          ) : (
            <Loading small={true} inline={true} />
          )}{' '}
          {tc.approveCostExcelInformationSummary2}
          {Number.isFinite(state.excel.rowPriceProspect) ? (
            state.excel.rowPriceProspect
          ) : (
            <Loading small={true} inline={true} />
          )}{' '}
          {tc.approveCostExcelInformationSummary3}
        </p>
        {state.props.origin === 'list' ? (
          <p>
            {tc.excelExcludeProspectsWithoutName1}{' '}
            <strong>
              {removeProspectsWithoutNames
                ? tc.exclude.toLowerCase()
                : tc.include.toLowerCase()}
            </strong>{' '}
            {tc.excelExcludeProspectsWithoutName2}
          </p>
        ) : null}
        <div className="excelWrapper__excel__content__summary__approveCost__button">
          <ToggleOption
            active={approveCost}
            label={tc.approveCost}
            labelSub={tc.approveCostExcel}
            onClick={() => {
              setApproveCost(!approveCost);
            }}
          />
        </div>
      </div>
    );
  };

  const _renderDownloadUrl = () => {
    return (
      <div className="excelWrapper__excel__content__summary__download">
        <p>{tc.excelExportPending}</p>
        <div className="excelWrapper__excel__content__summary__download__button">
          <ActionButton label={tc.close} onClick={_close} type="highlight" />
        </div>
      </div>
    );
  };

  const _renderPrice = () => {
    const priceIsSet =
      Number.isFinite(state.excel?.totalCost) &&
      (Number.isFinite(state.excel?.rowsProspects) ||
        Number.isFinite(state.excel?.rowsVehicles));

    return (
      <div className="excelWrapper__excel__content__summary__price">
        <span className="label">{tc.prices}</span>
        <div className="excelWrapper__excel__content__summary__price__row">
          {`${tc.orderedName}`}:{' '}
          {priceIsSet ? (
            `${state.excel.rowsOrderedName} ${tc.aPiece.toLowerCase()}`
          ) : (
            <Loading small={true} />
          )}
        </div>
        <div className="excelWrapper__excel__content__summary__price__row">
          {`${tc.orderedCompany}`}:{' '}
          {priceIsSet ? (
            `${state.excel.rowsOrderedManagement} ${tc.aPiece.toLowerCase()}`
          ) : (
            <Loading small={true} />
          )}
        </div>
        <div className="excelWrapper__excel__content__summary__price__row">
          {`${tc.pricePerRow} ${tc.prospect.toLowerCase()}`}:{' '}
          {priceIsSet ? (
            `${state.excel.rowPriceProspect} ${tc.swedishCrowns.toLowerCase()}`
          ) : (
            <Loading small={true} />
          )}
        </div>
        <div className="excelWrapper__excel__content__summary__price__row">
          {`${tc.pricePerRow} ${tc.vehicles.toLowerCase()}`}:{' '}
          {priceIsSet ? (
            `${state.excel.rowPriceVehicle} ${tc.swedishCrowns.toLowerCase()}`
          ) : (
            <Loading small={true} />
          )}
        </div>
        <div className="excelWrapper__excel__content__summary__price__row">
          {`${tc.rowsAmount} ${tc.prospects.toLowerCase()}`}:{' '}
          {priceIsSet ? state.excel.rowsProspects : <Loading small={true} />}
        </div>
        <div className="excelWrapper__excel__content__summary__price__row">
          {`${tc.rowsAmount} ${tc.vehicles.toLowerCase()}`}:{' '}
          {priceIsSet ? state.excel.rowsVehicles : <Loading small={true} />}
        </div>
        <div className="excelWrapper__excel__content__summary__price__row priceSum">
          {tc.priceSum}:{' '}
          {priceIsSet ? (
            `${state.excel.totalCost} ${tc.swedishCrowns.toLowerCase()}`
          ) : (
            <Loading small={true} />
          )}
        </div>
      </div>
    );
  };

  const _renderSelectedColumns = () => {
    const selectedFieldsUnsorted = [];

    for (const prop in columns) {
      if (Array.isArray(columns[prop])) {
        columns[prop].forEach((num) => {
          if (num.active) {
            selectedFieldsUnsorted.push(num);
          }
        });
      }
    }

    const selectedFieldsSorted = selectedFieldsUnsorted.sort((a, b) => {
      if (isNaN(a.index)) {
        a.index = 999;
      }

      if (isNaN(b.index)) {
        b.index = 999;
      }

      return a.index - b.index;
    });

    const selectedFields = selectedFieldsSorted.map((field) => {
      return field.label;
    });

    return (
      <div className="excelWrapper__excel__content__summary__selectedFields">
        <span className="label">{tc.selectedFields}</span>
        <div className="excelWrapper__excel__content__summary__selectedFields__items">
          {selectedFields.map((field, i) => (
            <span key={field + i}>{field}</span>
          ))}
        </div>
      </div>
    );
  };

  const _renderColumns = () => {
    const sections = [];
    let index = 0;
    for (const prop in columns) {
      index++;
      const content = (
        <div className="excelWrapper__excel__content__columns__bottom__section__content">
          {prop !== 'formatColumns' ? (
            <div
              className={
                _isSectionToggled(prop)
                  ? 'excelWrapper__excel__content__columns__bottom__section__content__toggleAll'
                  : 'excelWrapper__excel__content__columns__bottom__section__content__toggleAll active'
              }
              onClick={() => {
                _toggleSection(prop);
              }}
            >
              <Icon val={_isSectionToggled(prop) ? 'checkbox' : 'check'} />
              {_isSectionToggled(prop) ? tc.selectAll : tc.unselectAll}
            </div>
          ) : null}
          {columns[prop].map((num, i) => {
            return (
              <div
                className={
                  num.active
                    ? 'excelWrapper__excel__content__columns__bottom__section__content__item active'
                    : 'excelWrapper__excel__content__columns__bottom__section__content__item'
                }
                key={i}
                onClick={() => {
                  _toggleSelector(prop, num.val);
                }}
              >
                <Icon val={num.active ? 'check' : 'checkbox'} />
                {num.label}
              </div>
            );
          })}
        </div>
      );
      sections.push(
        <div
          className="excelWrapper__excel__content__columns__bottom__section"
          key={index}
        >
          <Collapsible
            content={content}
            header={excelHelper.getSectionHeading(prop)}
          />
        </div>
      );
    }

    return (
      <div className="excelWrapper__excel__content__columns">
        <div className="excelWrapper__excel__content__columns__top">
          <div className="excelWrapper__excel__content__columns__top__info">
            <p>{tc.excelInfoColumns}</p>
          </div>
          {state.props.origin === 'list' &&
          !createPreset &&
          !presetToEdit.id ? (
            <div className="excelWrapper__excel__content__columns__top__button">
              <ToggleOption
                active={removeProspectsWithoutNames}
                label={tc.removeProspectsWithoutNames}
                labelSub={tc.removeProspectsWithoutNamesInfo}
                onClick={() => {
                  setRemoveProspectsWithoutNames(!removeProspectsWithoutNames);
                }}
              />
            </div>
          ) : null}
        </div>
        <div className="excelWrapper__excel__content__columns__bottom">
          {sections}
        </div>
      </div>
    );
  };

  const _renderSelectTemplate = () => {
    return (
      <div className="excelWrapper__excel__content__selectTemplate">
        <div className="excelWrapper__excel__content__selectTemplate__info">
          <p>{tc.excelInfoGeneric}</p>
          <p>{tc.excelInfoSelectTemplate}</p>
        </div>
        <div className="excelWrapper__excel__content__selectTemplate__select">
          <Menu
            items={[
              state?.props?.origin !== 'fleet' && {
                active: selectedTemplate === 'prospect' && !createPreset,
                icon: 'prospect',
                label: tc.prospect,
                labelSub: tc.excelInfoProspectRows,
                onClick: () => {
                  setCreatePreset(false);
                  setSelectedTemplate('prospect');
                  setSelectedUserPresetType(null);
                },
                type: 'button',
              },
              {
                active: selectedTemplate === 'vehicle' && !createPreset,
                icon: 'car',
                label: tc.vehicle,
                labelSub: tc.excelInfoVehicleRows,
                onClick: () => {
                  setCreatePreset(false);
                  setSelectedTemplate('vehicle');
                  setSelectedUserPresetType(null);
                },
                type: 'button',
              },
            ]}
            type="large"
          />
        </div>
        <div className="excelWrapper__excel__content__selectTemplate__select">
          <Menu
            items={[
              state?.props?.origin !== 'fleet' && {
                active: selectedTemplate === 'prospect' && createPreset,
                icon: 'prospectPreset',
                label: tc.createPreset,
                labelSub: tc.createPresetProspect,
                onClick: () => {
                  setCreatePreset(true);
                  setSelectedTemplate('prospect');
                },
                type: 'button',
              },
              {
                active: selectedTemplate === 'vehicle' && createPreset,
                icon: 'prospectPreset',
                label: tc.createPreset,
                labelSub: tc.createPresetVehicle,
                onClick: () => {
                  setCreatePreset(true);
                  setSelectedTemplate('vehicle');
                },
                type: 'button',
              },
            ]}
            type="large"
          />
        </div>

        <div className="excelWrapper__excel__content__selectTemplate__select">
          {userPresetsIsLoading ? (
            <Loading />
          ) : (
            <Menu
              items={[
                state?.props?.origin !== 'fleet' && {
                  active: selectedTemplate === 'mailings',
                  icon: 'mail',
                  label: tc.templateMailings,
                  labelSub: tc.templateMailingsInfo,
                  onClick: () => {
                    setCreatePreset(false);
                    setSelectedTemplate('mailings');
                    setSelectedUserPresetType(null);
                    setRemoveProspectsWithoutNames(true);
                  },
                  type: 'button',
                },
                ...userPresets.map((preset) => ({
                  active: selectedTemplate === preset.id,
                  icon: 'mail',
                  label: `Mall: ${preset.name}`,
                  labelSub:
                    preset.type === 'prospect'
                      ? tc.createPresetProspect
                      : tc.createPresetVehicle,
                  onClick: () => {
                    setCreatePreset(false);
                    setSelectedTemplate(preset.id);
                    setSelectedUserPresetType(preset.type);
                  },
                  type: 'button',
                })),
              ]}
              type="delimited"
              centered={true}
            />
          )}
        </div>
      </div>
    );
  };

  const _renderSummary = () => {
    return (
      <div className="excelWrapper__excel__content__summary">
        {_renderSelectedColumns()}
        {_renderPrice()}
        {_renderApproveCost()}
      </div>
    );
  };

  const setSettings = async () => {
    try {
      let columnsCloned = JSON.parse(JSON.stringify(columns));

      // Settings array only holds "val".
      let newSettingsArr = [];
      for (const prop in columnsCloned) {
        if (Array.isArray(columnsCloned[prop])) {
          newSettingsArr = newSettingsArr.concat(
            columnsCloned[prop]
              .filter((num) => num.active)
              .map((num) => num.val)
          );
        }
      }

      let settings = {
        ...state.user.settings.settings,
        excel: {
          ...state.user.settings.settings.excel,
          [state.props.origin]: newSettingsArr,
        },
      };

      await updateSettings({
        settings: settings,
        skipGetSettings: true,
      });
      getSettings();
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Update state and also save/remove fields to settings.
   *
   * @param section
   */
  const _toggleSection = async (section) => {
    let columnsCloned = JSON.parse(JSON.stringify(columns));

    if (columnsCloned[section].find((num) => !num.active)) {
      // Toggle all to checked.
      columnsCloned[section].map((num) => (num.active = true));
    } else {
      // Toggle all to unchecked.
      columnsCloned[section].map((num) => (num.active = false));
    }

    setColumns(columnsCloned);
  };

  /**
   * Update state and also save/remove field to settings.
   *
   * @param section
   * @param val
   */
  const _toggleSelector = async (section, val) => {
    let columnsCloned = JSON.parse(JSON.stringify(columns));

    if (Array.isArray(columnsCloned[section])) {
      columnsCloned[section].map((num) => {
        if (num.val === val) {
          num.active = !num.active;
        }
        return num;
      });
    }

    setColumns(columnsCloned);
  };

  const _setActiveColumnsFromSettings = () => {
    if (
      selectedTemplate !== 'mailings' &&
      selectedTemplate !== 'vehicle' &&
      selectedTemplate !== 'prospect' &&
      selectedTemplate !== null
    ) {
      const selectedUserPreset = userPresets.find(
        (preset) => preset.id === Number(selectedTemplate)
      );

      const presetVals = selectedUserPreset.columns.map((col) => col.val);

      const columnsToggled = excelHelper.getColumns(
        selectedUserPreset?.type === 'prospect' ? 'prospect' : 'vehicle'
      );

      for (const prop in columnsToggled) {
        if (Array.isArray(columnsToggled[prop])) {
          columnsToggled[prop] = columnsToggled[prop].map((num) => {
            const existsInPreset = !!presetVals.includes(num.val);

            if (existsInPreset) {
              const presetData = selectedUserPreset.columns.find(
                (col) => col.val === num.val
              );

              return {
                ...presetData,
                active: true,
                label: presetData.userLabel
                  ? presetData.userLabel
                  : presetData.label,
              };
            } else {
              num.active = false;
              return num;
            }
          });
        }
      }

      setColumns(columnsToggled);
    } else {
      let columnsToggled = excelHelper.getColumns(selectedTemplate);

      if (selectedTemplate === 'mailings') {
        // For templates, set all fields to active.
        for (const prop in columnsToggled) {
          if (Array.isArray(columnsToggled[prop])) {
            columnsToggled[prop].map((num) => {
              num.active = true;
              return num;
            });
          }
        }
      } else if (
        state.user.settings?.settings?.excel &&
        Array.isArray(state.user.settings.settings.excel[state.props.origin])
      ) {
        // Set active fields from user settings.
        for (const prop in columnsToggled) {
          if (Array.isArray(columnsToggled[prop])) {
            columnsToggled[prop].map((num) => {
              num.active = !!state.user.settings.settings.excel[
                state.props.origin
              ].includes(num.val);
              return num;
            });
          }
        }
      }

      setColumns(columnsToggled);
    }
  };

  const _setResponsive = () => {
    _setResponsiveWidth();
  };

  const _setResponsiveWidth = () => {
    if (!excelRef?.current) {
      return;
    }

    const width = excelRef.current.getBoundingClientRect().width;
    const breakpoint1 = 600;

    if (width <= breakpoint1) {
      setResponsiveClassWidth('excelResponsiveWidth1');
    } else if (width > breakpoint1) {
      setResponsiveClassWidth('');
    }
  };

  return (
    <Popup close={_close} size={stage === 1 || stage === 2 ? 'big' : 'medium'}>
      <div className={`excelWrapper ${responsiveClassWidth}`} ref={excelRef}>
        <div className="excelWrapper__excel">
          <div className="excelWrapper__excel__header">
            <WidgetHeader
              headline={tc.excelOutput}
              headlineSub={
                stage === 4 && state.excel.downloadUrl ? null : loading ? (
                  `${tc.preparingDownload}`
                ) : !!columns ? (
                  tc.excelOutputInfo
                ) : (
                  <Loading small={true} />
                )
              }
            />
          </div>
          {loading ? (
            <Loading />
          ) : (
            <>
              <div className="excelWrapper__excel__content">
                {state.props.origin === 'list' &&
                !createPreset &&
                !presetToEdit.id ? (
                  <div className="excelWrapper__excel__content__target">
                    <Highlight
                      text={`${
                        tc.excelOutput
                      } ${tc.for.toLowerCase()} ${tc.lists.toLowerCase()}: ${
                        state.props.selectedLists
                          ? state.props.selectedLists
                              .map((num) => num.name)
                              .join(',')
                          : null
                      }`}
                    />
                  </div>
                ) : null}
                {!!columns
                  ? stage === 1
                    ? _renderSelectTemplate()
                    : stage === 2
                      ? _renderColumns()
                      : stage === 3
                        ? _renderSummary()
                        : stage === 4
                          ? _renderDownloadUrl()
                          : null
                  : null}
                {stage === 100 && ( // Hur ska flödet se ut för att välja mall/skapa mall?
                  <ExcelPreset
                    columns={columns.carColumns
                      .concat(columns.userColumns)
                      .concat(columns.techColumns)
                      .concat(columns.wheelColumns)} // TODO!
                    cancel={() => {
                      setSelectedTemplate(null);
                      setStage(1);
                      setPresetToEdit({});
                    }}
                    onSave={() => {
                      setSelectedTemplate(null);
                      setStage(1);
                      setPresetToEdit({});
                    }}
                    presetType="car"
                    presetToEdit={presetToEdit}
                  />
                )}
                {stage === 101 && ( // Hur ska flödet se ut för att välja mall/skapa mall?
                  <ExcelPreset
                    columns={columns.userColumns.concat(columns.contactColumns)} // TODO!
                    cancel={() => {
                      setSelectedTemplate(null);
                      setPresetToEdit({});
                      setStage(1);
                    }}
                    onSave={() => {
                      setSelectedTemplate(null);
                      setStage(1);
                      setPresetToEdit({});
                    }}
                    presetType="prospect"
                    presetToEdit={presetToEdit}
                  />
                )}
              </div>
              <div className="excelWrapper__excel__footer">
                {stage === 1 ? (
                  <WidgetFooter
                    disableButtonOne={selectedTemplate === null}
                    buttonOneFunc={() => {
                      if (selectedTemplate === 'mailings') {
                        _getPrice();
                        setStage(3);
                      } else if (
                        selectedTemplate !== 'vehicle' &&
                        selectedTemplate !== 'prospect' &&
                        selectedTemplate !== 'mailings'
                      ) {
                        _getPrice();
                        setStage(3);
                      } else {
                        setStage(2);
                      }
                    }}
                    buttonOneText={tc.next}
                    buttonTwoFunc={_close}
                    buttonTwoText={tc.cancel}
                    buttonThreeText={tc.edit}
                    buttonThreeFunc={() => {
                      const p = userPresets.find(
                        (preset) => preset.id === selectedTemplate
                      );
                      setPresetToEdit(p);
                      setStage(2);
                    }}
                    disableButtonThree={
                      selectedTemplate === 'vehicle' ||
                      selectedTemplate === 'prospect' ||
                      selectedTemplate === 'mailings' ||
                      selectedTemplate === null
                    }
                    buttonFourText={tc.remove}
                    buttonFourFunc={() => {
                      deletePreset(selectedTemplate);
                    }}
                    disableButtonFour={
                      selectedTemplate === 'vehicle' ||
                      selectedTemplate === 'prospect' ||
                      selectedTemplate === 'mailings' ||
                      selectedTemplate === null
                    }
                  />
                ) : stage === 2 ? (
                  <WidgetFooter
                    buttonOneFunc={async () => {
                      await setSettings();
                      if (createPreset) {
                        if (selectedTemplate === 'vehicle') {
                          return setStage(100);
                        } else {
                          return setStage(101);
                        }
                      }

                      if (presetToEdit && presetToEdit.id) {
                        if (presetToEdit.type === 'car') {
                          return setStage(100);
                        }

                        return setStage(101);
                      }
                      _getPrice();
                      setStage(3);
                    }}
                    buttonOneText={tc.next}
                    buttonTwoFunc={() => {
                      setPresetToEdit({});
                      setSelectedTemplate(null);
                      setSelectedUserPresetType(null);
                      setStage(1);
                    }}
                    buttonTwoText={tc.goBack}
                  />
                ) : stage === 3 ? (
                  <WidgetFooter
                    buttonOneFunc={_convertToExcel}
                    buttonOneText={tc.downloadAndOrderExcel}
                    disableButtonOne={
                      !Number.isFinite(state.excel.totalCost) || !approveCost
                    }
                    buttonTwoFunc={() => {
                      setPresetToEdit({});
                      if (selectedTemplate === 'mailings') {
                        setSelectedTemplate(null);
                        setSelectedUserPresetType(null);
                        setStage(1);
                      } else if (
                        selectedTemplate !== 'vehicle' &&
                        selectedTemplate !== 'prospect' &&
                        selectedTemplate !== 'mailings'
                      ) {
                        setStage(1);
                      } else {
                        setStage(2);
                      }
                    }}
                    buttonTwoText={tc.goBack}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
    </Popup>
  );
};

const MapStateToProps = (state, props) => {
  return {
    excel: state.excel,
    props: props,
    user: state.user,
  };
};

export default connect(MapStateToProps)(Excel);
