import React from 'react';
import Icon from 'components/icon';

/**
 * Render a button in two styles.
 *
 * @param props.active - bool (optional)
 * @param props.class - string (optional) - If ever needed to send in external class.
 * @param props.disabled - bool (optional)
 * @param props.href - string - Either this or onClick.
 * @param props.icon - string (optional) - Icon val.
 * @param props.label - string
 * @param props.onClick - func - This or href.
 * @param props.type - "regular" | "highlight" (optional)
 * @param props.buttonType - "submit" | "reset" (optional)
 */
const ActionButton = (props) => {
  const _onClick = () => {
    if (typeof props.onClick === 'function') {
      props.onClick();
    }
  };

  return props.href ? (
    <div className="actionButtonWrapper">
      <a
        className={`actionButtonWrapper__actionButton ${
          props.class ? props.class : ''
        } ${props.type === 'highlight' ? 'highlight' : 'regular'} ${
          props.active ? 'active' : ''
        } ${props.disabled ? 'disabled' : ''}`}
        href={!props.disabled ? props.href : ''}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.icon ? <Icon val={props.icon} /> : null} {props.label}
      </a>
    </div>
  ) : (
    <div className="actionButtonWrapper">
      <button
        className={`actionButtonWrapper__actionButton ${
          props.class ? props.class : ''
        } ${props.type === 'highlight' ? 'highlight' : 'regular'} ${
          props.active ? 'active' : ''
        } ${props.disabled ? 'disabled' : ''}`}
        onClick={_onClick}
        type={props.buttonType}
      >
        {props.icon ? <Icon val={props.icon} /> : null}
        {props.label}
      </button>
    </div>
  );
};

export default ActionButton;
