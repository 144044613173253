import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import { saveProspectsToList } from 'store/lists/tasks';
import { getLists } from 'store/lists/tasks';
import { tableHelper, tc } from 'helpers';
import { Table } from 'components/table';
import Loading from 'components/loading';
import Menu from 'components/menu';
import Popup from 'components/popup';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import ToggleOption from 'components/toggle_option';

/**
 * Render a component that saves prospect ids to a new or existing list.
 *
 * Can also be used to render the lists and just return the selected lists or new name (see _saveToList()).
 *
 * @param state.props.close - func
 * @param state.props.prospects - array - Array with prospect ids.
 * @param state.props.text - string (optional) - If we want to add some text.
 * @param state.props.view - "existing" | "new" (optional) - What view to start in, defaults to existing.
 */
const SaveToList = (state) => {
  const [lists, setLists] = useState([]);
  const [listName, setListName] = useState('');
  const [showExisting, setShowExisting] = useState(state.props.view !== 'new');
  const [includeKoncern, setIncludeKoncern] = useState(false);
  const [includeFilial, setIncludeFilial] = useState(false);
  const newListNameInputRef = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    getLists({});
  }, []);

  const _saveToList = async () => {
    if (state.props.save && typeof state.props.save === 'function') {
      // If we want to use this component for any other purpose than saveProspectsToList, just return lists or name.
      if (showExisting) {
        return state.props.save({ lists: lists });
      } else {
        return state.props.save({ name: listName });
      }
    } else {
      if (showExisting) {
        state.props.close();
        return await saveProspectsToList({
          lists: lists,
          prospectIds: state.props.prospects,
          includeKoncern,
          includeFilial,
          isCompany: true,
        });
      } else {
        state.props.close();
        return await saveProspectsToList({
          name: listName,
          prospectIds: state.props.prospects,
          includeKoncern,
          includeFilial,
          isCompany: true,
        });
      }
    }
  };

  const origin = state.props.origin;

  const _stateCheck = () => {
    return !!(state?.props && state?.lists?.lists);
  };

  return (
    <Popup close={state.props.close} size="big">
      <div className="saveToListWrapper">
        <div className="saveToListWrapper__saveToList">
          <div className="saveToListWrapper__saveToList__header">
            <WidgetHeader headline={tc.saveToList} />
          </div>
          <div className="saveToListWrapper__saveToList__content">
            <div className="saveToListWrapper__saveToList__content__menu">
              <Menu
                skipSort={true}
                type="floatFree"
                centered={true}
                //border={true}
                items={[
                  {
                    active: showExisting,
                    label: tc.existingLists,
                    onClick: () => {
                      setShowExisting(true);
                    },
                    type: 'button',
                  },
                  {
                    active: !showExisting,
                    label: tc.createNewList,
                    onClick: () => {
                      setShowExisting(false);
                    },
                    type: 'button',
                  },
                ]}
              />
            </div>
            {origin === 'company' && (
              <div className="saveToListWrapper__saveToList__content__toggle">
                <ToggleOption
                  active={includeKoncern}
                  label={'Inkludera koncern'}
                  onClick={() => {
                    setIncludeKoncern(!includeKoncern);
                  }}
                />
                <ToggleOption
                  active={includeFilial}
                  label={'Inkludera filialer'}
                  onClick={() => {
                    setIncludeFilial(!includeFilial);
                  }}
                />
              </div>
            )}
            {state.props.text ? (
              <div className="saveToListWrapper__saveToList__content__text">
                {state.props.text}
              </div>
            ) : null}
            {showExisting ? (
              <>
                {_stateCheck() ? (
                  <div className="saveToListWrapper__saveToList__content__table">
                    <Table
                      columns={tableHelper.getListsCompactColumns()}
                      onSelect={(arr) => {
                        setLists(arr);
                      }}
                      rowsPerPage={5}
                      rows={tableHelper.getListsCompactRows(
                        state.lists.lists && state.lists.lists.length
                          ? state.lists.lists
                          : []
                      )}
                      selected={lists}
                    />
                  </div>
                ) : (
                  <Loading />
                )}
              </>
            ) : (
              <div className="saveToListWrapper__saveToList__content__newList">
                <p>{tc.nameNewList}:</p>
                <input
                  onChange={(e) => {
                    setListName(e.target.value);
                  }}
                  ref={newListNameInputRef}
                />
              </div>
            )}
          </div>
          <div className="saveToListWrapper__saveToList__footer">
            <WidgetFooter
              buttonOneFunc={_saveToList}
              buttonOneText={tc.save}
              buttonTwoFunc={state.props.close}
              buttonTwoText={tc.cancel}
            />
          </div>
        </div>
      </div>
    </Popup>
  );
};

const MapStateToProps = (state, props) => {
  return {
    lists: state.lists,
    props: props,
  };
};

export default connect(MapStateToProps)(SaveToList);
