import { useContext, useState } from 'react';
import DragAndDrop from './drag_and_drop';
import LanguageContext from 'language-context';
import EditButton from 'components/edit_button/edit_button';
import WidgetFooter from 'components/widget_footer';
import ActionButton from 'components/action_button';
import { request } from 'helpers';
import styles from './excel_preset.module.css';

export default function ExcelPreset({
  columns,
  cancel,
  presetType,
  presetToEdit,
  onSave,
}) {
  const cols = columns
    .filter((col) => col.active)
    .map((column, index) => {
      return {
        ...column,
        userLabel: '',
        id: isNaN(column.index) ? index + 1 : column.index + 1,
      };
    });

  const tc = useContext(LanguageContext);

  const [nameInput, setNameInput] = useState('');
  const [editName, setEditName] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [preset, setPreset] = useState({
    columns: cols,
    name:
      presetToEdit && presetToEdit.name
        ? presetToEdit.name
        : nameInput
          ? nameInput
          : tc.presetsExcel,
    type: presetType,
  });

  const saveNewLabel = (label, id) => {
    setPreset((preset) => {
      const columns = preset.columns.map((obj) => {
        return { ...obj, userLabel: obj.id === id ? label : obj.userLabel };
      });
      return { ...preset, columns };
    });
  };

  const saveNewPreset = async () => {
    try {
      setIsSaving(true);
      await request({
        url: '/settings/excelPresets',
        method: 'POST',
        data: { preset },
      });
      setIsSaving(false);
      onSave();
    } catch (err) {
      console.error(err);
    }
  };

  const editPreset = async () => {
    try {
      setIsSaving(true);
      await request({
        url: '/settings/excelPresets',
        method: 'PUT',
        data: { preset, presetId: presetToEdit.id },
      });
      setIsSaving(false);
      onSave();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div>
        {!editName && (
          <div className={styles.nameWrapper}>
            <p className={styles.name}>{preset.name}</p>
            <EditButton
              onClick={() => setEditName(true)}
              tooltip={tc.editName}
            />
          </div>
        )}
        {editName && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setPreset((preset) => {
                return { ...preset, name: nameInput || preset.name };
              });
              setEditName(false);
            }}
            onReset={() => {
              setEditName(false);
            }}
          >
            <label>
              <input
                placeholder={preset.name}
                value={nameInput}
                onChange={(e) => setNameInput(e.target.value)}
                onFocus={(e) => {
                  if (!nameInput) {
                    setNameInput(
                      presetToEdit && presetToEdit.name
                        ? presetToEdit.name
                        : tc.presetsExcel
                    );
                  }

                  e.target.select();
                }}
              />
            </label>
            <div className={styles.buttonContainer}>
              <ActionButton
                buttonType="submit"
                label={tc.save}
                class={styles.actionButton}
              />
              <ActionButton
                buttonType="reset"
                label={tc.cancel}
                class={styles.actionButton}
              />
            </div>
          </form>
        )}

        <DragAndDrop
          rows={preset.columns}
          onChange={(result) => {
            setPreset((preset) => {
              return {
                ...preset,
                columns: result.map((col, i) => ({
                  ...col,
                  index: col.id - 1,
                })),
              };
            });
          }}
          onEdit={saveNewLabel}
        />
      </div>

      <WidgetFooter
        buttonOneFunc={() =>
          presetToEdit && presetToEdit.id ? editPreset() : saveNewPreset()
        }
        buttonOneDisabled={isSaving}
        buttonOneText={tc.save}
        buttonTwoFunc={cancel}
        buttonTwoText={tc.cancel}
      />
    </div>
  );
}
