export const companyActionTypes = {
  SET_COMPANY: 'SET_COMPANY',
  SET_COMPANY_INFO: 'SET_COMPANY_INFO',
  SET_COMPANY_EMAILS: 'SET_COMPANY_EMAILS',
  SET_COMPANY_PHONENUMBERS: 'SET_COMPANY_PHONENUMBERS',
  SET_COMPANY_RESPONSIBLE: 'SET_COMPANY_RESPONSIBLE',
  SET_COMPANY_RESPONSIBLE_KONCERN: 'SET_COMPANY_RESPONSIBLE_KONCERN',
  SET_COMPANY_KONCERN: 'SET_COMPANY_KONCERN',
  SET_COMPANY_DEALS: 'SET_COMPANY_DEALS',
  SET_COMPANY_IN_LISTS: 'SET_COMPANY_IN_LISTS',
  SET_COMPANY_CONTRACTS: 'SET_COMPANY_CONTRACTS',
};
