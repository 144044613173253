import Icon from 'components/icon';
import styles from './delete_button.module.css';
import TooltipComponent from 'components/tooltip';

export default function DeleteButton({ onClick = () => {}, tooltip = '' }) {
  return (
    <button className={styles.button} onClick={onClick}>
      <TooltipComponent tooltipContent={tooltip}>
        <Icon val="remove" />
      </TooltipComponent>
    </button>
  );
}
