import { useContext, useState, useRef } from 'react';
import styles from './drag_and_drop.module.css';
import clsx from 'clsx';
import EditButton from 'components/edit_button/edit_button';
import DeleteButton from 'components/delete_button/delete_button';
import LanguageContext from 'language-context';
import ActionButton from 'components/action_button';

/**
 *
 * @param {{rows: {label: string; userLabel: string; id: number; }[]
 * onChange: (result: Array) => void
 * onEdit: (label, id) => void
 * }} props
 * @returns
 */
export default function DragAndDrop({
  rows = [],
  onChange = (_result) => {},
  onEdit = (_label, _id) => {},
}) {
  const [targetId, setTargetId] = useState(null);
  const [draggedId, setDraggedId] = useState(null);
  const [editId, setEditId] = useState(null);
  const [userLabel, setUserLabel] = useState('');
  const tc = useContext(LanguageContext);
  const isBetween = (id, a, b) => {
    const max = Math.max(a, b);
    const min = Math.min(a, b);
    return id < max && id > min;
  };
  return [...rows]
    .sort((a, b) => a.id - b.id)
    .map((item, index) => {
      if (editId === item.id)
        return (
          <div key={item.id} className={styles.wrapper}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onEdit(userLabel, item.id);
                setEditId(null);
                setUserLabel('');
              }}
              onReset={() => {
                setEditId(null);
                setUserLabel('');
              }}
            >
              <label>
                <input
                  type="text"
                  placeholder={item.userLabel ? item.userLabel : item.label}
                  value={userLabel}
                  onChange={(e) => setUserLabel(e.target.value)}
                  onFocus={(e) => {
                    if (!userLabel) {
                      setUserLabel(
                        item.userLabel ? item.userLabel : item.label
                      );
                    }

                    e.target.select();
                  }}
                />
              </label>
              <div className={styles.buttonContainer}>
                <ActionButton
                  buttonType="submit"
                  label={tc.save}
                  class={styles.actionButton}
                />
                <ActionButton
                  buttonType="reset"
                  label={tc.cancel}
                  class={styles.actionButton}
                />
              </div>
            </form>
          </div>
        );
      return (
        <div className={styles.wrapper} key={item.id}>
          <div
            className={clsx(
              styles.row,
              item.id === draggedId && styles.dragged,
              item.id === targetId && styles.target
            )}
            onDrag={(e) => {
              setDraggedId(Number(e.currentTarget.id));
            }}
            onDragEnter={(e) => {
              setTargetId(Number(e.currentTarget.id));
            }}
            onDragLeave={(e) => {
              if (
                e.target.classList.contains('button-container') ||
                e.target.classList.contains('button')
              ) {
                return;
              }
              setTargetId(null);
            }}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            id={item.id}
            draggable={editId ? false : true}
            onDrop={() => {
              if (!targetId || !draggedId) {
                setTargetId(null);
                setDraggedId(null);
                return;
              }
              if (targetId === draggedId) {
                setTargetId(null);
                setDraggedId(null);
                return;
              }
              const result = rows.map((item) => {
                if (item.id === draggedId) {
                  return { ...item, id: targetId };
                }
                if (item.id === targetId) {
                  return {
                    ...item,
                    id: targetId > draggedId ? targetId - 1 : targetId + 1,
                  };
                }
                if (isBetween(item.id, targetId, draggedId)) {
                  return {
                    ...item,
                    id: item.id < targetId ? item.id - 1 : item.id + 1,
                  };
                } else {
                  return item;
                }
              });

              onChange(result);
              setTargetId(null);
              setDraggedId(null);
            }}
          >
            <div className={styles.columnName}>
              <p>
                {item.userLabel || item.label} ({item.id})
              </p>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <EditButton
              onClick={() => {
                setEditId(item.id);
                setUserLabel('');
              }}
              tooltip={tc.editColumnName}
            />
            <DeleteButton
              onClick={() => {
                onChange(rows.filter((row) => row.id !== item.id));
              }}
              tooltip={tc.deleteColumn}
            />
          </div>
        </div>
      );
    });
}
