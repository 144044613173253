import React from 'react';
import MenuButton from './menu_button';

/**
 * Render a menu with large buttons.
 *
 * @param props.items - array - Example: [
 *  {
 *       active: true,
 *       disabled: isDisabled,
 *       icon: "excludeProspects",
 *       label: tc.excludeProspects,
 *       labelSub: tc.excludeProspectsSubscriptionInfo, // This is only displayed when menu type is 'large'.
 *       onClick: () => {
 *         _toggleSubscribeFlagValue(1);
 *       },
 *       type: "button",
 *   },
 * ]
 */
const MenuLarge = (props) => {
  return (
    <div className="menuLargeWrapper">
      <div className="menuLargeWrapper__menuLarge">
        <div className="menuLargeWrapper__menuLarge__items">
          {props.items
            .filter((num) => num)
            .map((num, i) => {
              if (num.type === 'button') {
                return (
                  <MenuButton
                    active={num.active}
                    disabled={num.disabled}
                    icon={num.icon}
                    key={i}
                    label={num.label}
                    labelSub={num.labelSub}
                    onClick={() => {
                      if (!num.disabled && typeof num.onClick === 'function') {
                        num.onClick();
                      }
                    }}
                    type="large"
                  />
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default MenuLarge;
