import Icon from 'components/icon';
import styles from './edit_button.module.css';
import TooltipComponent from 'components/tooltip';

export default function EditButton({ onClick = () => {}, tooltip = '' }) {
  return (
    <button className={styles.button} onClick={onClick}>
      <TooltipComponent tooltipContent={tooltip}>
        <Icon val="edit" />
      </TooltipComponent>
    </button>
  );
}
